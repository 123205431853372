<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              預約管理
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'BookingSelectList' }">
              預約列表
            </b-breadcrumb-item>

            <b-breadcrumb-item active>
              {{ bookingPreset.name }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-8 mb-2 font-weight-bold">{{ bookingPreset.name }}</h4>
        <b-input-group class="col-12 col-xl-4 mb-2">
          <b-form-input v-model="keyword" placeholder="搜尋"></b-form-input>
          <b-input-group-append>
            <b-button @click="fetchBookings"><i class="fa fa-search"></i ></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.BOOKING_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'BookingCreate', params: { id: bookingPreset.id }}"
            ><i class="fa fa-plus"></i>新增預約</b-button
          >
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="bookings"
                :fields="fields"
              >
                <template #cell()="data">
                  <template v-if="isObject(data.value)">
                    <RouterLink v-if="data.value.type === 'collection_select' && checkPermission([consts.COLLECTION_ITEM_MANAGE])" :to="{ name: 'CollectionItemEdit', params: {id: data.value.collection_id, item_id: data.value.collection_item_id } }">
                      {{ data.value.text }}
                    </RouterLink>
                    <template v-else>{{ data.value.text }}</template>
                  </template>
                  <template v-else>{{ data.value }}</template>
                </template>
                <template #cell(customer)="data">
                  <b-avatar
                    v-if="data.item.customer && data.item.customer.avatar_url"
                    class="mx-1"
                    size="2em"
                    :src="data.item.customer.avatar_url"
                  ></b-avatar>
                  <span v-if="!data.item.customer" class="text-muted">無會員</span>
                  <span v-else>{{ displayCustomer(data.item.customer) }}</span>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_EDIT])"
                    variant="inverse-warning"
                    :to="{ name: 'BookingEdit', params: { id: bookingPreset.id, booking_id: data.item.id }}"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_DELETE])"
                    variant="inverse-danger"
                    @click="deleteBooking(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchBookings"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>

</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import { format } from "date-fns";
import bookingApi from "@/apis/booking";
import collectionApi from "@/apis/collection";
import { mapState } from "vuex";

export default {
  data() {
    return {
      consts: consts,
      keyword: '',
      showLoading: false,
      fieldsInit: false,
      fields: [
        {
          key: 'customer',
          label: '預約會員',
        },
        {
          key: 'created_at',
          label: '建立時間',
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
          },
        },
        { key: 'actions', label: '管理' },
      ],
      bookings: [],
      bookingPreset: {
        id: null,
        name: null,
        config: null,
      },
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      collections: [],
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.bookingPreset.id = this.$route.params.id;
    if (!this.bookingPreset.id) {
      this.$swal("錯誤", "找不到預約設定檔", "error");
      return;
    }
    this.fetchBookingPreset();
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    displayCustomer(customer) {
      if (!customer) {
        return "無會員";
      }
      return customer.name + (customer.mobile_phone ? ` ${customer.mobile_phone}` : '');
    },
    async fetchBookingPreset () {
      try {
        this.showLoading = true;
        const response = await bookingApi.getBookingPreset(this.bookingPreset.id);
        this.bookingPreset = response.data.data;

        if (!this.bookingPreset.config?.fields || this.bookingPreset.config.fields.length === 0) {
          this.$swal("提醒", "此預約尚未設定欄位，須設定欄位後才能新增及編輯預約", "warning");
          this.$router.push({
            name: "BookingPresetList",
          });
          return;
        }

        let newField = [];

        for (let i = 0; i < this.bookingPreset.config.fields.length; i++) {
          const field = this.bookingPreset.config.fields[i];

          if (field.config?.list_hidden === true) {
            continue;
          }

          let fieldConfig = {
            key: field._id ?? field.config._id,
            label: field.config.title,
            sortable: true,
          };

          console.log(field);

          if (field.type === "collection_select" && !this.collections.find((collection) => collection.id === field.config.collection_id)) {
            let collectionResponse = await collectionApi.getCollection(field.config.collection_id);
            this.collections.push(collectionResponse.data.data);
          }

          newField.push(fieldConfig);
        }

        this.fields.unshift(...newField);



        await this.fetchBookings();
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取設定錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBookings () {
      try {
        this.showLoading = true;
        const response = await bookingApi.getBookings(this.bookingPreset.id, {
          keyword: this.keyword,
          per_page: this.perPage,
          page: this.currentPage,
        });

        this.bookings = response.data.data.map((booking) => {
          let newBooking = {
            ...booking
          };
          for (let i = 0; i < booking.data.length; i ++) {
            let data = booking.data[i];

            // 如果遇到陣列型的資料，將陣列轉為字串
            if (Array.isArray(data.value)) {
              newBooking[data.field_id] = data.value.join(', ');
              continue;
            }

            console.log(this.collections);

            // 如果遇到 collection_select，就把 collection_items 的值取出來
            if (typeof data.value === 'object' && data.value !== null && data.value.type === 'collection_select') {
              console.log(data);
              const collection = this.collections.find((collection) => collection.id === data.value.collection_id);

              if (!collection) {
                continue;
              }

              const collectionItem = collection.items.find((item) => item.id === data.value.collection_item_id);
              console.log('collectionItem', collectionItem);

              const mainDisplayField = collection.config.fields.find((field) => field.main_display);
              if (! mainDisplayField) {
                // data.value = collectionItem.data[0].value;
                data.value = {
                  ...data.value,
                  text: collectionItem.data[0].value,
                }
              } else {
                let mainDisplayData = collectionItem.data.find((data) => data.field === mainDisplayField.id).value;
                if (mainDisplayData) {
                  // data.value = mainDisplayData;
                  data.value = {
                    ...data.value,
                    text: mainDisplayData,
                  }
                }
              }
            }

            newBooking[data.field_id] = data.value;
          }

          return newBooking;
        });

        this.totalRows = response.data.data.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料集項目錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deleteBooking(booking) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "刪除預約",
          text: `是否要將此預約刪除？`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "移除",
          reverseButtons: true,
        })

        if (! result.value) {
          return
        }

        await bookingApi.deleteBooking(this.bookingPreset.id, booking.id);
        this.$swal('刪除成功', '', 'success');
        await this.fetchBookings();
      } catch (error) {
        console.error(error);
        this.$swal('刪除失敗', '', 'error');
      } finally {
        this.showLoading = false;
      }
    },
    isObject(field) {
      return typeof field === 'object' && field !== null;
    },
  }
}
</script>
